import App from '@localMonitor/app.vue'
import { router } from '@localMonitor/router'
import Vue from 'vue'
import wizville from '@vue/plugins/wizville'
import vuetify from '@vue/plugins/vuetify'

import { i18n, setLanguageAsync } from '@i18n/setup'

import VueAnalytics from 'vue-analytics'
import { env, production, staging } from "@shared/helpers/env-helpers.js"

if (production) {
  console.info("loading ga")

  Vue.use(VueAnalytics, {
    id: 'UA-31753084-13',
    router
  })
}

router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  if (lang) {
    setLanguageAsync(lang).then(() => next())
  } else {
    next()
  }
})

document.addEventListener('DOMContentLoaded', () => {
  setLanguageAsync('default', { rootPath: "local_monitor" }).then(() => {
    const el = document.body.appendChild(document.createElement('localMonitor'))

    window.app = new Vue({
      el,
      vuetify,
      wizville,
      i18n,
      router,
      render: h => h(App)
    })
  })
})
