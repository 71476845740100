<template>
  <footer>
    <div class="made-with-desktop">
      <p>
        made with
        <v-icon :style="{ color: '#cb3939' }">
          mdi-heart
        </v-icon>
        in Paris by
        <a href="https://wizville.fr/" target="_blank">
          Wizville
        </a>
      </p>
    </div>
    <div class="nav-links">
      <router-link :to="{ name: 'why-local-monitor' }" class="line-break">
        {{ $t("local_monitor.why.url_name") }}
      </router-link>
      -
      <router-link :to="{ name: 'terms' }">
        {{ translate("terms_word") }}
      </router-link>
      -
      <a :href="cguLink" target="_blank">
        {{ translate("cgu_word") }}
      </a>
      -
      <a :href="$t('local_monitor.faq_url')" target="_blank">
        FAQ
      </a>
    </div>
    <div class="made-with-mobile">
      <p>
        made with
        <v-icon :style="{ color: '#cb3939' }">
          mdi-heart
        </v-icon>
        in Paris by
        <a href="https://wizville.fr/" target="_blank">
          Wizville
        </a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    cguLink() {
      if (this.$i18n.locale === "fr") {
        return "http://ow.ly/W6zl1028zT0"
      } else {
        return "http://ow.ly/k2ng1028OrZ"
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@localMonitor/assets/stylus/medias.styl'

a
  color: #bdbdbd
  &.line-break
    +forBreakpoints(mobile) {
      display: block
    }
footer
  color: #bdbdbd
  background-color: #fff
  font-size: 0.85rem
  box-sizing: border-box
  +forBreakpoints(desktop) {
    display: flex
  }

.made-with, .nav-links
  display: inline-block
  box-sizing: border-box

.made-with-desktop
  display: none
  +forBreakpoints(desktop) {
    text-align: left
    width: 50%
    padding-left: 20px
    position: relative
    display: block
  }


.made-with-mobile
  display: block
  text-align: center
  width: 100%
  padding-left: 0
  +forBreakpoints(desktop){
    display: none
  }

.nav-links
  text-align: right
  width: 49%
  padding-right: 20px
  position: relative
  z-index: 0
  +forBreakpoints(mobile) {
    bottom: 3px
  }
  +forBreakpoints(mobile){
    width: 99%
    text-align: center
  }
  +forBreakpoints(tablet){
    width: 99%
    text-align: center
  }
</style>
