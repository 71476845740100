import ApiCall from '@api/apiCall.js'

async function translate(keyTextHash, from, to) {
  if (!from) {
    throw new Error('Cannot translate from locale, the parameter is empty')
  }
  if (!to) {
    throw new Error('Cannot translate to locale, the parameter is empty')
  }
  const response = await new ApiCall().post(`/api/wizville/translates/translate`, {
    keyTextHash, from, to
  })

  return response.data
}

async function updateTranslation(translationId, value, locale) {
  let formData = new FormData();
  formData.append("translation_id", translationId)
  formData.append("value", value)
  formData.append("locale", locale)

  const response = await new ApiCall().post(`/api/wizville/translates/update_translation`, formData)

  return response.data
}

export { updateTranslation, translate }
