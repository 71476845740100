<template>
  <VApp id="localMonitor">
    <meta 
      name="viewport" 
      content="width=device-width,initial-scale=1"
    >
    <router-view />
  </VApp>
</template>

<script>
  import Vue from 'vue'

  export default {
    methods: {
      setTitleAndMeta() {
        window.document.title = this.$i18n.t('local_monitor.meta.title')
        window.document.querySelector('meta[name="description"]').setAttribute("content", this.$i18n.t('local_monitor.meta.description'))
      }
    },
    watch: {
      "$i18n.locale": {
        handler: "setTitleAndMeta",
        immediate: true
      }
    }
  }
</script>

<style lang="stylus">
.v-application
  margin-bottom: 0!important
.text-bold
  font-weight: bold!important
</style>
