<template>
  <WTable
    :rows="formattedItems"
    :stripped="true"
    :shadow="false"
    :paginated="false"
    class="w-weight-repartition"
  >
    <template slot-scope="{row}">
      <slot name="item" :item="row">
        <td
          class="item-name text-left f-13"
          :class="{ nowrap: $vuetify.breakpoint.smAndUp && row.name.length <= 42 }"
        >
          <slot name="item-name" :item="row">
            {{row.name}}
          </slot>
        </td>

        <td class="item-badge">
          <slot name="item-badge" :item="row">
            <v-badge color="lightgrey" :content="row.value || '0'"/>
          </slot>
        </td>

        <td class="item-weight-bar">
          <div class="weight-bar" :style="row.style"></div>
        </td>

        <td class="mr-1" style="width: 5%;">
          <span class="score font-weight-bold primary--text d-flex">
            <slot name="item-score" :item="row">
              <span class="nowrap" v-if="row && row.weight">
                <span v-if="row.weight > minLabelValue">
                  {{row.weight.toFixed(weightDecimals)}}{{weightUnit}}
                </span>
                <span v-else>
                  &lt; {{minLabelValue}}{{weightUnit}}
                </span>
              </span>

              <w-color-sign
                v-if="row.weightEvolution && row.weightEvolution !== 0"
                :value="row.weightEvolution"
                class="pl-1"
                :decimals="weightEvolutionDecimals"
                :reverse="weightEvolutionReverse"
              />
            </slot>
          </span>
        </td>

        <td style="width: 5%;" v-if="row.click">
          <slot name="item-link" :item="row">
            <WIcon
              icon="eye"
              :icon-style="{ fontSize: '13px', color: '#C1BFC7' }"
            />
          </slot>
        </td>
      </slot>
    </template>
  </WTable>
</template>

<script>
export default {
  name: "WWeightRepartition",
  props: {
    items: { type: Array, default: [] },
    defaultBarColor: { type: String, default: "blue" },
    weightUnit: { type: String, default: "%" },
    minLabelValue: { type: Number, default: Number.MIN_SAFE_INTEGER },
    weightDecimals: { type: Number, default: 0 },
    weightEvolutionDecimals: { type: Number, default: 0 },
    weightEvolutionReverse: { type: Boolean, default: false }
  },
  methods: {
    show(truc) { console.log(truc) }
  },
  computed: {
    formattedItems() {
      const items = this.items.filter(e => e.weight).sort((a, b) => b.weight - a.weight)
      const maxWeight = items[0]?.weight || 0

      return items.map((item, index) => {
        let weightPercent = 0

        if (maxWeight > 0) {
          weightPercent = ((item.weight || 0) / maxWeight) * 100

          if (weightPercent < 1) {
            weightPercent = 1
          }
        }

        return {
          ...item,
          style: [
            `width: ${weightPercent}%`,
            `background-color: ${item.barColor ? item.barColor : this.defaultBarColor}`,
          ].join(';'),
          position: index + 1
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .w-weight-repartition
    td
      vertical-align: middle

    .item-name,
    .item-badge
      width: 45px

    .item-weight-bar
      .weight-bar
        height: 18px
        display: inline-block
        vertical-align: middle
        border-radius: 2px
        position relative
        bottom: 3px
</style>
