<template>
  <img :src="imageUrl" :alt="$i18n.t('local_monitor.model_report.alt')" />
</template>

<script>
  const STORAGE_HOST = "https://storage.googleapis.com/wizville-public-eu/images/local_monitor"

  export default {
    name: "WizMonitorResults",
    computed: {
      imageUrl() {
        return `${STORAGE_HOST}/demo-report-${this.$i18n.locale}.png`
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@localMonitor/assets/stylus/medias.styl'

  img
    margin-top: 20px
    width: 90%
</style>
