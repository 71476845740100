import { _upperCase } from 'lodash/upperCase'

import WizClient from "@shared/http/wizClient"
import { Transaction } from '@api/transaction.js'
import { trackEvent as trackAnalyticsEvent } from '@shared/helpers/analytics/base'


export default class ApiCall {
  constructor(transactionType = 'api') {
    this.transactionType = transactionType
    this.httpAction = null
    this.endpoint = null
    this.params = null
    this.result = null
  }

  async get(endpoint, params = {}) {
    return await this.resolve({ httpAction: 'get', endpoint, params })
  }

  async post(endpoint, params = {}) {
    return await this.resolve({ httpAction: 'post', endpoint, params })
  }

  async put(endpoint, params = {}) {
    return await this.resolve({ httpAction: 'put', endpoint, params })

  }
  async delete(endpoint, params = {}) {
    return await this.resolve({ httpAction: 'delete', endpoint, params })
  }

  async resolve({ httpAction, endpoint, params }) {
    const transaction = new Transaction(this.transactionType, endpoint)
 
    this.httpAction = httpAction
    this.endpoint = endpoint
    this.params = params

    transaction.start(this)
    try {
      if (httpAction === 'get') {
        this.result = await WizClient.get(endpoint, params)
      } else if (httpAction === 'post') {
        this.result = await WizClient.post(endpoint, params)
      } else if (httpAction === 'put') {
        this.result = await WizClient.put(endpoint, params)
      } else if (httpAction === 'delete') {
        this.result = await WizClient.delete(endpoint, params)
      } else {
        throw "Bad httpAction"
      }

      this.trackApiCall(httpAction, endpoint, params)

      transaction.ok(this.result)
    } catch (error) {
      transaction.ko(error)
      throw error
    } 
    
    return this.result
  }

  debug() {
    return `
      const response = await new ApiCall(
        '${this.transactionType}'
      ).${this.httpAction}(
        '${this.endpoint}',
        ${JSON.stringify(this.params)}
      )

      return response.data
    `
  }

  async trackApiCall(httpAction, endpoint, params) {
    if (endpoint !== '/api/wizville/tracking_events' ) {
      const eventName = this.buildEventName(httpAction, endpoint, params)
      
      await trackAnalyticsEvent(eventName, { params: params })
    }
  }

  buildEventName(httpAction, endpoint, params) {
    let [pathname, _] = endpoint.split('?');
    
    const cleanedPathname = pathname.split('/').map(segment => {
      const filterSegment = 
        Object.values(params).includes(segment) ||
        /\d/.test(segment)

      return filterSegment ? ':param' : segment
    }).join('/')

    return `${httpAction.toUpperCase()}#${cleanedPathname}`
  }
}