<template>
   <hr style="border-top: 1px solid #E5E7E8" class="my-3 w-hr" />
</template>

<script>
  export default {
    name: "WHr"
  }
</script>

<style lang="stylus" scoped>
</style>