import LocalMonitorLayout from '@localMonitor/layouts/LocalMonitorLayout'

export default [
  {
    path: '/:lang([a-z][a-z])?',
    component: LocalMonitorLayout,

    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@localMonitor/pages/Home'),
        beforeEnter: (to, from, next) => {
          if (from.name === "competitors") {
            amplitude.getInstance().logEvent('Page_2_Retour');
          }
          else{
            amplitude.getInstance().logEvent('Page_1_Affichage');
          }
          next()
        }
      },
      {
        path: 'why-local-monitor',
        name: 'why-local-monitor',
        component: () => import('@localMonitor/pages/Why')
      },
      {
        path: 'competitors/:id',
        name: 'competitors',
        component: () => import('@localMonitor/pages/Competitor')
      },
      {
        path: 'email/:id/:competitorIds',
        name: 'email',
        component: () => import('@localMonitor/pages/Email'),
        beforeEnter: (to, from, next) => {
          if (from.name === "competitors") {
            amplitude.getInstance().logEvent('Page_2_Validation');
          }
          next()
        }
      },
      {
        path: 'validation',
        name: 'validation',
        component: () => import('@localMonitor/pages/Validation'),
        beforeEnter: (to, from, next) => {
          if (from.name === "email") {
            amplitude.getInstance().logEvent('Page_4_Email_confirmation');
          }
          next()
        }
      },
      {
        path: 'setup-success',
        name: 'setup-success',
        component: () => import('@localMonitor/pages/SetupSuccess'),
        beforeEnter: (to, from, next) => {
          amplitude.getInstance().logEvent('Adresse_Email_Confirmée');
          next()
        }
      },
      {
        path: 'subscription-success',
        name: 'subscription-success',
        component: () => import('@localMonitor/pages/SubscriptionSuccess')
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import('@localMonitor/pages/Terms')
      }
    ]
  },
]
