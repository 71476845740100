<template>
  <div class="local-monitor-layout container is-fullhd">
    <div class="columns local-monitor-layout-content is-gapless">
      <div class="column is-three-fifths main-left-column column has-text-centered">
        <div class="header-section">
          <Header />
        </div>

        <div class="content-section">
          <div :class="{ 'content-column': $route.name != 'why-local-monitor' }">
            <router-view />
          </div>
        </div>

        <div class="footer-section">
          <Footer />
        </div>
      </div>
      <div class="column main-right-column is-hidden-mobile">
        <WizMonitorReport />
        <div class="triangle-wrapper">
          <div class="bottom-triangle">
            <!-- eslint-disable vue/no-v-html -->
            <span class="quote">
              <span
                class="quote-text"
                v-html="translate('quote.text')"
              />
              <br>
              <span
                class="quote-author"
                v-html="translate('quote.author')"
              />
            </span>
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from './Header'
  import Footer from './Footer'
  import WizMonitorReport from '@localMonitor/components/WizMonitor/WizMonitorReport'

  export default {
    name: 'layout',
    components: {
      Header,
      Footer,
      WizMonitorReport
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@localMonitor/assets/stylus/medias.styl'

  .triangle-wrapper
    filter: drop-shadow(-2px -1px 4px rgba(0, 0, 0, .5))
    position: sticky
    width: 100%
    height: 50%
    bottom: 0
    right: 0
    .bottom-triangle
      background-image: url("~@localMonitor/assets/images/localmonitor-background.png")
      background-size: cover
      clip-path: polygon(100% 0, 0% 100%, 100% 100%)
      width: 100%
      height: 100%
      .quote
        position: absolute
        bottom: 5%
        right: 5%
        color: white
        line-height: 2vw
        letter-spacing: 0.51px
        text-align: right
        .quote-text
          font-weight: bold
          font-size: 1.5vw
        .quote-author
          font-size: 1.3vw

  .columns.is-gapless
    margin: 0px
    padding: 0px

  .content-section
    position: relative
    text-align: center
    z-index: 100

  .content-column
    position: absolute

    +forBreakpoints(mobile){
      position: relative
      padding-bottom: 5px
    }
    margin-top: 16%
    left: 50%
    z-index: 1000
    -webkit-transform: translateX(-50%)
    transform: translateX(-50%)
    +forBreakpoints(mobile) {
      margin-top: 10%
    }
    width: 100%
    padding-left: 30px
    padding-right: 30px
    +forBreakpoints(tablet){
      width: 36rem
    }
    +forBreakpoints(desktop) {
      width: 36rem
    }

  .local-monitor-layout
    background-color: white
    max-width: 100%
    padding: 0px
    margin: 0px
    box-sizing: border-box

    .local-monitor-layout-content
      box-sizing: border-box

    .main-left-column, .main-right-column
      box-sizing: border-box
      +forBreakpoints(desktop) {
        height: 100vh
      }

    .main-left-column
      +forBreakpoints(desktop) {
        overflow: scroll
      }
      
    .header-section, .content-section, .footer-section
      box-sizing: border-box

    .main-right-column
      position: relative
      .report-cover
        z-index: 2
        width: 100%
        position: absolute
        bottom: 0

    .header-section
      height: 7vh
      position: relative
      z-index: 1000

    .content-section
      min-height: 90vh
      margin-top: -2%
      +forBreakpoints(mobile) {
        min-height: 650px;
      }

    .footer-section
      height: 3vh
      +forBreakpoints(desktop_on_zoom){
        margin-top: 10%
      }

    .main-right-column
      text-align: center
      background: #1d6f95
      background: -moz-linear-gradient(top,  #1d6f95 0%, #40b6b0 100%)
      background: -webkit-linear-gradient(top,  #1d6f95 0%,#40b6b0 100%)
      background: linear-gradient(to bottom,  #1d6f95 0%,#40b6b0 100%)
      position: relative
      overflow: scroll

</style>
