import Vue from 'vue'
import Router from 'vue-router'

import paths from './paths'

Vue.use(Router)
export const router = new Router({
  // This removes # from the route
  mode: 'history',
  routes: paths
})
