<template>
  <div v-resize="onResize" class="w-repartition-horizontal-frieze flex-grow-1" :style="{ minWidth: minWidth, maxWidth: maxWidth }">
    <slot name="item-bar">
      <div class="horizontal-rankings d-flex align-center" :style="{ height, width: '100%' }">
        <div
          v-if="part.number > 0"
          v-for="(part, idx) in barParts"
          :data-part-type="part.type"
          :key="idx"
          :style="{background: part.color, width: `${barWidths[part.type]}%`}"
          class="horizontal-ranking"
          ref="parts"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <div v-on="on" class="d-flex flex-column align-center justify-center icon-content">
                <div
                  v-if="emoticons && displayEmoticons && displayEmoticons[part.type]"
                  class="d-flex flex-row justify-center align-center info-content bg-white"
                >
                  <WIcon v-on="on" :icon="part.emoticon" class="smiley-icon top-0"/>
                  <span
                    v-if="displayLabels && displayLabels[part.type]"
                    class="f-12 c-lightgrey"
                  >
                    {{ part.label || barWidths[part.type] }}{{ unit }}
                  </span>
                </div>
                <span v-else v-on="on" class="fb-12" style="height: 100%; width: 100%;" />

                <span v-if="part.subLabel && displayLabels && displayLabels[part.type]" :class="subLabelFontColor(part.type)" class="fb-12 sub-label">{{part.subLabel}}</span>
              </div>
            </template>

            <div class="d-flex align-center">
               <div class="bottom_1px" v-if="!part.tooltip">
                  <WIcon
                    :icon="part.emoticon"
                    class="mr-1"
                    style="font-size: 13px"
                  />
                </div>
                <div class="fs-12" v-html="part.tooltip || `${part.number} (${barWidths[part.type]}%)`" />
            </div>
          </v-tooltip>
        </div>

        <span v-if="itemScore === 0 || itemScore" class="score font-weight-bold primary--text nowrap pl-2" :style="{ height }">
          <slot name="item-score" :item="itemScore">
            <span v-if="itemScore === 0 || itemScore">{{ Number(itemScore) ? itemScore.toFixed(decimals) : itemScore }}</span>
          </slot>
        </span>
      </div>

      <div v-if="hasLegend" class="mt-2 d-flex f-12 lh-12 c-legend legend-items">
        <div class="d-flex align-center legend-item">
          <div class="square bg-red"></div>
          <div>{{ detractorsLegend }}</div>
        </div>
        <div class="d-flex align-center legend-item">
          <div class="square bg-neutralgrey"></div>
          <div>{{ neutralsLegend }}</div>
        </div>
        <div class="d-flex align-center legend-item">
          <div class="square bg-gradient-green-start"></div>
          <div>{{ promotersLegend }}</div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>

export default {
  name: "WRepartitionHorizontalFrieze",
  props: {
    nbPromoters: { type: Number, default: 0 },
    nbDetractors: { type: Number, default: 0 },
    nbNeutrals: { type: Number, default: 0 },
    promotersLabel: { type: String, required: false, default: null },
    detractorsLabel: { type: String, required: false, default: null },
    neutralsLabel: { type: String, required: false, default: null },
    promotersSubLabel: { type: String, required: false, default: null },
    detractorsSubLabel: { type: String, required: false, default: null },
    neutralsSubLabel: { type: String, required: false, default: null },
    promotersTooltip: { type: String, required: false, default: null },
    detractorsTooltip: { type: String, required: false, default: null },
    neutralsTooltip: { type: String, required: false, default: null },
    promotersLegend: { type: String, required: false, default: null },
    detractorsLegend: { type: String, required: false, default: null },
    neutralsLegend: { type: String, required: false, default: null },
    unit: { type: String, required: false, default: "%" },
    itemScore: { type: Number, default: null },
    height: { type: String, default: "25px" },
    minWidth: { type: String, default: "200px" },
    maxWidth: { type: String, default: "200px" },
    emoticons: { type: Boolean, default: false },
    decimals: { type: Number, default: 0 },
  },
  data() {
    return {
      refreshEmoticons: 0
    }
  },
  computed: {
    total() {
      return (this.nbPromoters + this.nbDetractors + this.nbNeutrals)
    },
    barWidths() {
      const detractorsWidth = this.$options.filters.toPercent(this.nbDetractors / this.total)
      const neutralsWidth = this.$options.filters.toPercent(this.nbNeutrals / this.total)

      const promotersWidth = 100 - detractorsWidth - neutralsWidth

      return { detractors: detractorsWidth, promoters: promotersWidth, neutrals: neutralsWidth }
    },
    barParts() {
      return [
        {
          number: this.nbDetractors,
          type: 'detractors',
          emoticon: 'smiley-angry',
          label: this.detractorsLabel,
          subLabel: this.detractorsSubLabel,
          tooltip: this.detractorsTooltip,
          color: "var(--red)"
        },
        {
          number: this.nbNeutrals,
          type: 'neutrals',
          emoticon: 'smiley-neutral',
          label: this.neutralsLabel,
          subLabel: this.neutralsSubLabel,
          tooltip: this.neutralsTooltip,
          color: "var(--neutralgrey)"
        },
        {
          number: this.nbPromoters,
          type: 'promoters',
          emoticon: 'smiley-happy',
          label: this.promotersLabel,
          subLabel: this.promotersSubLabel,
          tooltip: this.promotersTooltip,
          color: 'var(--gradient-green)'
        }
      ]
    },
    displayEmoticons() {
      let displayEmoticons = {}

      if (this.refreshEmoticons && this.$refs.parts?.length > 0) {
        ['detractors', 'neutrals', 'promoters'].forEach(function(type) {
          const element = this.$refs.parts ?
            this.$refs.parts.filter((el) => el.getAttribute('data-part-type') === type)[0] :
            null
          if (element) {
            displayEmoticons[type] = element.clientWidth >= 30
          }

        }, this)
      }

      return displayEmoticons
    },
    displayLabels() {
      let displayLabels = {}

      if (this.refreshEmoticons && this.$refs.parts?.length > 0) {
        ['detractors', 'neutrals', 'promoters'].forEach(function(type) {
          const element = this.$refs.parts ?
            this.$refs.parts.filter((el) => el.getAttribute('data-part-type') === type)[0] :
            null
          if (element) {
            displayLabels[type] = element.clientWidth >= 60
          }

        }, this)
      }

      return displayLabels
    },
    hasLegend() {
      return this.promotersLegend && this.neutralsLegend && this.detractorsLegend
    }
  },
  methods: {
    subLabelFontColor(type) {
      return type == "neutrals" ? "c-legend" : "c-white"
    },
    onResize() {
      this.refreshEmoticons++
    }
  }
}
</script>

<style lang="stylus" scoped>
  .w-repartition-horizontal-frieze
    line-height: initial

    .legend-items
      gap: 1.2em

    .legend-item
      gap: 0.3em

    .square
      border-radius: 2px
      width: 8px
      height: 8px

    .icon-content
      height: 100%
      gap: 0.4em

    .item-name
      height: 100%
      display: inline-block
      max-width: 150px

    .sub-label
      text-shadow: 1px 1px 1px 63636396

    .item-name-table
      text-align: left
      vertical-align: initial

    .smiley-icon
      font-size: 14px

    .horizontal-rankings
      display: inline-block
      vertical-align: middle
      position: relative

    .horizontal-rankings-table
      text-align: left
      width: 100%

    .horizontal-ranking
      height: 100%
      display: inline-block
      width: 85%

    .horizontal-span
      height: 100%
      display: inline-block
      width: 5%

    .score
      vertical-align: initial

    .horizontal-rankings-table > .horizontal-ranking:first-of-type
      border-top-left-radius: 4
      border-bottom-left-radius: 4px

    .horizontal-rankings-table > .horizontal-ranking:last-of-type
      border-top-right-radius: 4px
      border-bottom-right-radius: 4px

    .info-content
      border-radius: 100px
      gap: 0.2em
      padding: 2px 4px

    .horizontal-rankings > .horizontal-ranking:first-of-type
      border-top-left-radius: 4px
      border-bottom-left-radius: 4px

    .horizontal-rankings > .horizontal-ranking:last-of-type
      border-top-right-radius: 4px
      border-bottom-right-radius: 4px
</style>
