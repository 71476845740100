function trackUser(currentUserId, currentUserData) {
  window.mixpanel.identify(currentUserId)
  window.mixpanel.people.set(currentUserData)
}

function resetUser() {
  window.mixpanel.reset()
}

function trackPageView(data) {
  window.mixpanel.track_pageview(data)
}

function trackEvent(eventLabel, data) {
  window.mixpanel.track(eventLabel, data)
}

export default { trackUser, resetUser,  trackPageView, trackEvent}